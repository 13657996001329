// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-type-template-js": () => import("/opt/build/repo/src/templates/typeTemplate.js" /* webpackChunkName: "component---src-templates-type-template-js" */),
  "component---src-templates-organization-or-project-template-js": () => import("/opt/build/repo/src/templates/organizationOrProjectTemplate.js" /* webpackChunkName: "component---src-templates-organization-or-project-template-js" */),
  "component---src-templates-sector-template-js": () => import("/opt/build/repo/src/templates/sectorTemplate.js" /* webpackChunkName: "component---src-templates-sector-template-js" */),
  "component---src-templates-person-template-js": () => import("/opt/build/repo/src/templates/personTemplate.js" /* webpackChunkName: "component---src-templates-person-template-js" */),
  "component---src-templates-job-template-js": () => import("/opt/build/repo/src/templates/jobTemplate.js" /* webpackChunkName: "component---src-templates-job-template-js" */),
  "component---src-templates-article-or-opportunity-template-js": () => import("/opt/build/repo/src/templates/articleOrOpportunityTemplate.js" /* webpackChunkName: "component---src-templates-article-or-opportunity-template-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-success-jsx": () => import("/opt/build/repo/src/pages/contact-success.jsx" /* webpackChunkName: "component---src-pages-contact-success-jsx" */),
  "component---src-pages-contact-jsx": () => import("/opt/build/repo/src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-index-jsx": () => import("/opt/build/repo/src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-organizations-jsx": () => import("/opt/build/repo/src/pages/organizations.jsx" /* webpackChunkName: "component---src-pages-organizations-jsx" */),
  "component---src-pages-portfolio-jsx": () => import("/opt/build/repo/src/pages/portfolio.jsx" /* webpackChunkName: "component---src-pages-portfolio-jsx" */),
  "component---src-pages-team-jsx": () => import("/opt/build/repo/src/pages/team.jsx" /* webpackChunkName: "component---src-pages-team-jsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

